import React, { Component } from "react";
import * as SelectRadix from "@radix-ui/react-select";
import Header from "../../components/header/home";
import PageLayout from "../account/layout";
import ProgressBar from "../../components/progress/indeterminate";
import PageMenu from "../account/menuLibrary";
import EmptyView from "../../components/list/empty";
import Select from "../../components/form/select";
import DataTable from "./datatable";
import Button from "../../components/form/button";

class PageLibrary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileId: null,
    };
  }

  componentDidMount() {
    document.title = "Bibliothèque - Yoka.cd";
    setTimeout(() => this.fetchProfile(), 500);
  }

  componentDidUpdate(prevProps, prevState) {}

  fetchProfile() {
    const { getProfiles, getReleases } = this.props;
    if (getProfiles) getProfiles({ kind: "artist" });
    if (getReleases) getReleases({});
  }

  filterByProfileId(profile_id) {
    const { getReleases } = this.props;
    if (getReleases)
      getReleases({
        profile_id: profile_id === "none-selected" ? null : profile_id,
      });
  }

  onCreateRelease() {
    const { profiles } = this.props;
    const [firstProfile] = profiles;

    if (!firstProfile) return;

    let url = `/my-account/music/create?profile_id=${firstProfile.id}`;
    this.props.history.push(url);
  }

  render() {
    const {
      loading,
      loadingReleases,
      profiles = [],
      releases = [],
    } = this.props;

    const profilesEmpty = !loading && profiles.length === 0;
    const dataEmpty = !loadingReleases && releases.length === 0;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Ma bibliothèque</h1>
            <div className="py-10 pt-5">
              <PageMenu selectedIndex={3} />
            </div>
            {loading && <ProgressBar />}

            {profilesEmpty ? (
              <EmptyView
                title={"Aucun profil artiste crée"}
                onPrimaryAction={() => {
                  let url = "/my-account/profiles/create?type=artist";
                  this.props.history.push(url);
                }}
                onSecondaryAction={() => {
                  this.props.history.push("/my-account");
                }}
              />
            ) : (
              <>
                <div className="flex">
                  <div className="space-y-3 md:w-1/3">
                    <Select
                      label={"Filtrer par profil"}
                      onChange={(profile_id) =>
                        this.filterByProfileId(profile_id)
                      }
                      options={[
                        {
                          label: "Sélectionner un profil",
                          value: "none-selected",
                        },
                        ...profiles.map((item) => ({
                          label: item.name,
                          value: item.id,
                        })),
                      ]}
                    />
                  </div>
                </div>

                <hr className="my-5" />
                <DataTable
                  linkTopic="music"
                  dataSource={releases}
                  isEmpty={dataEmpty}
                  emptyTitle={"Vous n'avez pas encore de sortie."}
                  emptyDescription={"Lancez-vous et créez votre sortie !"}
                  onRequestCreate={() => this.onCreateRelease()}
                />
                {!dataEmpty && (
                  <div className="flex justify-end">
                    <div>
                      <Button
                        iconLeft={null}
                        iconRight={null}
                        disabled={loading}
                        content="Créer nouvelle sortie"
                        buttonStyle={"primary"}
                        onClick={() => this.onCreateRelease()}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageLibrary;
