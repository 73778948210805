import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { generateUUID } from "../../utils/StringUtil";

const listId = `list__${Math.random()}_${generateUUID()}`;
const listRef = React.createRef();

const DataList = ({
  listTitle = "List title",
  items = [],
  renderItem = null,
}) => {
  const [index, setIndex] = useState(1);

  useEffect(() => {}, []);
  return (
    <div className="py-10">
      <div className="px-20 flex justify-between">
        <h1 className="text-4xl font-semibold">{listTitle}</h1>

        <div className="flex space-x-3"></div>
      </div>
      <div
        className="pt-10 space-y-5 pl-20 pr-20 overflow-hidden"
        id={listId}
        ref={listRef}
      >
        {items.map((item, x) => renderItem({ item, x }))}
      </div>
    </div>
  );
};

DataList.propTypes = {};

export default DataList;
