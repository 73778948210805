import React from "react";

const Select = ({
  label,
  nameOrId,
  options = [],
  onChange = null,
  ...rest
}) => (
  <>
    {label && (
      <label
        htmlFor={nameOrId}
        className="block text-sm font-semibold text-gray-700"
      >
        {label}
      </label>
    )}
    <select
      {...rest}
      id={nameOrId}
      name={nameOrId}
      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-5 py-3 sm:text-sm md:text-base border-gray-300"
      onChange={(ev) => {
        let value = ev.target.value;
        if (onChange) onChange(value);
      }}
    >
      {options.map((item, x) => (
        <option key={x} value={item.value} disabled={item.disabled || false}>
          {item.label}
        </option>
      ))}
    </select>
  </>
);

export default Select;
