import React, { Component } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import FooterPage from "../../components/footer";
import Header from "../../components/header/home";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";
import Select from "../../components/form/select";
import DatePicker from "../../components/form/datepicker";
import Checkbox from "../../components/form/checkbox";

class PageSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountEmail: "",
      accountPassword: "",
      profileName: "",
      genderSex: "not_precised",
      birthDate: null,
      termsAccepted: false,
    };
  }

  componentDidMount() {
    document.title = "Inscription - Yoka.cd";
  }

  onRequestSubmit() {
    const { accountPassword, accountEmail, profileName, birthDate, genderSex } =
      this.state;

    console.log(this.state);

    if (
      accountPassword === "" ||
      accountEmail === "" ||
      profileName === "" ||
      birthDate === null
    )
      return;

    let dateIsValid = moment(birthDate).isValid();
    if (!dateIsValid) {
      toast("La date de naissance est invalide", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    let bodyParams = {
      username: accountEmail,
      password: accountPassword,
      profile_name: profileName,
      gender_sex: genderSex,
      birth_date: birthDate,
      auth_provider: "email",
    };

    const { createUser } = this.props;
    if (createUser) createUser(bodyParams);
  }

  render() {
    const { loading } = this.props;
    const { termsAccepted } = this.state;
    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <div className="bg-white py-10">
          <div className="h-full flex flex-col justify-center items-center">
            <div className="mx-auto md:container xl:max-w-2xl 2xl:w-[35rem] border border-gray-100 bg-gray-50 p-20">
              <h1 className="text-4xl font-bold leading-tight my-3 text-center">
                Inscrivez-vous gratuitement à Yoka.cd
              </h1>
              <div className="space-y-5 my-4">
                <p className="text-center">
                  Connectez-vous et retrouvez tout votre contenu !
                </p>
              </div>

              <div className="space-y-3">
                <Button
                  disabled={loading}
                  content="Inscrivez-vous avec Facebook"
                  iconRight={null}
                  iconLeft={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  }
                />
                <Button
                  disabled={loading}
                  content="Inscrivez-vous avec Google"
                  iconRight={null}
                  iconLeft={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 512 512"
                    >
                      <path d="M473.16 221.48l-2.26-9.59H262.46v88.22H387c-12.93 61.4-72.93 93.72-121.94 93.72-35.66 0-73.25-15-98.13-39.11a140.08 140.08 0 01-41.8-98.88c0-37.16 16.7-74.33 41-98.78s61-38.13 97.49-38.13c41.79 0 71.74 22.19 82.94 32.31l62.69-62.36C390.86 72.72 340.34 32 261.6 32c-60.75 0-119 23.27-161.58 65.71C58 139.5 36.25 199.93 36.25 256s20.58 113.48 61.3 155.6c43.51 44.92 105.13 68.4 168.58 68.4 57.73 0 112.45-22.62 151.45-63.66 38.34-40.4 58.17-96.3 58.17-154.9 0-24.67-2.48-39.32-2.59-39.96z" />
                    </svg>
                  }
                />
              </div>
              <hr className="my-10" />
              <div className="space-y-3">
                <TextField
                  type="email"
                  nameOrId="user-email"
                  label={"Quelle est votre adresse e-mail ?"}
                  placeholder="ex : wick@continental.com"
                  onChange={(accountEmail) => this.setState({ accountEmail })}
                />
                <TextField
                  type="password"
                  nameOrId="user-password"
                  label={"Créez un mot de passe"}
                  placeholder="Mot de passe"
                  onChange={(accountPassword) =>
                    this.setState({ accountPassword })
                  }
                />
                <TextField
                  type="email"
                  nameOrId="profile-name"
                  label={"Comment doit-on vous appeler ?"}
                  placeholder="ex : Johnatan Wick"
                  onChange={(profileName) => this.setState({ profileName })}
                />
                <Select
                  nameOrId={"gender-sex"}
                  label={"Quel est votre sexe ?"}
                  onChange={(genderSex) => this.setState({ genderSex })}
                  options={[
                    { value: "not_precised", label: "Non précisé" },
                    { value: "male", label: "Homme" },
                    { value: "female", label: "Femme" },
                  ]}
                />
                <DatePicker
                  nameOrId={"birth-date"}
                  label={"Quelle est votre date de naissance ?"}
                  onChange={(birthDate) => this.setState({ birthDate })}
                />

                <Checkbox
                  checked={termsAccepted}
                  label={"J'accepte les Conditions générales de Yoka."}
                  onChange={(termsAccepted) => this.setState({ termsAccepted })}
                />
              </div>
              <div className="space-y-3 mt-10 flex flex-col">
                <p className="text-xs">
                  Pour en savoir plus sur la façon dont Yoka recueille, utilise,
                  partage et protège vos données personnelles, veuillez
                  consulter{" "}
                  <a
                    href="/legal/privacy"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary-500"
                  >
                    la Politique de confidentialité de Yoka
                  </a>
                </p>

                <Button
                  disabled={loading || !termsAccepted}
                  content="S'inscrire"
                  iconRight={null}
                  iconLeft={null}
                  onClick={() => this.onRequestSubmit()}
                />
                <div className="flex space-x-2 text-center justify-center">
                  <a
                    className="md:inline-flex flex items-center justify-center mt-5 px-10 py-2 rounded-full text-primary-500 bg-white hover:bg-primary-500 hover:text-white"
                    href={"/sign-in"}
                  >
                    <span className="text-sm font-bold">
                      Vous avez déjà un compte ?
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterPage />
      </div>
    );
  }
}

export default PageSignup;
