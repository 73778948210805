import React, { Component } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class PageMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuItems: [
        {
          text: "Podcasts",
          link: `/my-account/podcasts`,
        },
        {
          text: "Prédications",
          link: `/my-account/preachs`,
        },
        {
          text: "Cours",
          link: `/my-account/learning`,
        },
        {
          text: "Musique",
          link: `/my-account/music`,
        },
      ],
    };
  }

  render() {
    const { menuItems } = this.state;
    const { selectedIndex = 0 } = this.props;
    return (
      <div className="border-b border-gray-200">
        <div className="-mb-px flex px-4 space-x-8 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          {menuItems.map((item, index) => (
            <a href={item.link}>
              <div
                className={classNames(
                  selectedIndex === index
                    ? "text-indigo-600 border-indigo-600"
                    : "text-gray-900 border-transparent",
                  "flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium"
                )}
              >
                {item.text}
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default PageMenu;
