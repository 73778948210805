import { toast } from "react-toastify";
import {
  profileGet,
  profileEdit,
  accountPassword,
  profileCreate,
  profileList,
} from "../api/userService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "PROFILE_SET_VALUE",
  propKey,
  propValue,
});

export const getProfile =
  (profileId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    let accessToken = getCookie("access_token");
    params["access_token"] = accessToken;

    dispatch(setKeyValue("loading", true));
    profileGet(profileId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { profile } = response;

        if (profile) {
          const { name, email_address, birth_date, gender_sex, id } = profile;

          let profileId = `${id}-${new Date().getTime()}`;
          dispatch(setKeyValue("name", name));
          dispatch(setKeyValue("email_address", email_address));
          dispatch(setKeyValue("birth_date", birth_date));
          dispatch(setKeyValue("gender_sex", gender_sex));
          dispatch(setKeyValue("profileId", profileId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
      });
  };

export const editProfile =
  (profileId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    profileEdit(profileId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { profile } = response;

        if (profile) {
          toast("Mise à jour du profil réussie !", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Echec lors de la mise à jour du profil !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const editPassword =
  (bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    accountPassword(bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { password_edited } = response;

        if (password_edited) {
          toast("Mise à jour du mot de passe réussie !", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Echec lors de la mise à jour du mot de passe !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const createProfile =
  (bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    profileCreate(bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { profile } = response;

        if (profile) {
          dispatch(setKeyValue("createdProfileId", profile.id));
          toast("Mise à jour du mot de passe réussie !", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Echec lors de la mise à jour du mot de passe !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const getProfiles =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    let accessToken = getCookie("access_token");
    params["access_token"] = accessToken;

    dispatch(setKeyValue("loading", true));
    profileList(params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { profiles } = response;

        if (profiles) {
          let profiles_list_id = `profiles_list__${new Date().getTime()}`;
          dispatch(setKeyValue("profiles", profiles));
          dispatch(setKeyValue("profiles_list_id", profiles_list_id));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
      });
  };
