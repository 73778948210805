import React, { Component } from "react";
import Header from "../../components/header/home";
import PageLayout from "./layout";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";
import ProgressBar from "../../components/progress/indeterminate";
import { getCookie } from "../../helpers/cookies";
import { toast } from "react-toastify";

class PagePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileId: null,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  componentDidMount() {
    document.title = "Mot de passe - Yoka.cd";
    setTimeout(() => this.fetchProfile(), 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { profileId, profileName, emailAddress, genderSex, birthDate } =
      this.props;

    if (profileId !== prevState.profileId) {
      this.setState({
        profileName,
        emailAddress,
        genderSex,
        birthDate,
        profileId,
      });
    }
  }

  fetchProfile() {
    let profileId = getCookie("profile_id");

    const { getProfile } = this.props;
    if (getProfile) getProfile(profileId);
  }

  onRequestSubmit() {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    if (
      currentPassword === "" ||
      newPassword === "" ||
      confirmPassword === ""
    ) {
      toast("Veuillez compléter tous les champs", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast("Les nouveaux mot de passe ne correspondent pas !", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    let bodyParams = {
      password_current: currentPassword,
      password_new: confirmPassword,
    };

    const { editPassword } = this.props;
    if (editPassword) editPassword(bodyParams);
  }

  render() {
    const { loading } = this.props;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Modifier le mot de passe</h1>
            <hr className="my-5" />
            {loading && <ProgressBar />}
            <div className="flex">
              <div className="space-y-3 md:w-1/2">
                <TextField
                  readOnly={loading}
                  type="password"
                  nameOrId="current-password"
                  label={"Mot de passe actuel"}
                  placeholder="xxxxxxxxxxxxx"
                  onChange={(currentPassword) =>
                    this.setState({ currentPassword })
                  }
                />
                <TextField
                  readOnly={loading}
                  type="password"
                  nameOrId="new-password"
                  label={"Nouveau mot de passe"}
                  placeholder="xxxxxxxxxxxxx"
                  onChange={(newPassword) => this.setState({ newPassword })}
                />
                <TextField
                  readOnly={loading}
                  type="password"
                  nameOrId="new-password-confirm"
                  label={"Mot de passe actuel"}
                  placeholder="xxxxxxxxxxxxx"
                  onChange={(confirmPassword) =>
                    this.setState({ confirmPassword })
                  }
                />
              </div>
            </div>

            <hr className="my-5" />
            <div className="flex justify-end space-x-3">
              <div>
                <Button
                  disabled={loading}
                  iconLeft={null}
                  iconRight={null}
                  content="Annuler"
                  onClick={() => {
                    this.props.history.push("/my-account");
                  }}
                />
              </div>
              <div>
                <Button
                  iconLeft={null}
                  iconRight={null}
                  disabled={loading}
                  content="Enregistrer le profil"
                  buttonStyle={"primary"}
                  onClick={() => this.onRequestSubmit()}
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PagePassword;
