import React from "react";

export default function TextField({
  prefix,
  label,
  suffix,
  defaultValue,
  type = "text",
  nameOrId = "input_0",
  placeholder = "placeholder",
  onChange,
  ...rest
}) {
  return (
    <div>
      {label && (
        <label
          htmlFor={nameOrId}
          className="block text-sm font-semibold text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative shadow-sm">
        {prefix && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
        )}
        <input
          {...rest}
          type={type}
          id={nameOrId}
          name={nameOrId}
          defaultValue={defaultValue}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-5 py-3 sm:text-sm md:text-base border-gray-300"
          placeholder={placeholder}
          onChange={(ev) => {
            let value = ev.target.value;
            if (onChange) onChange(value);
          }}
        />
        {suffix && suffix}
      </div>
    </div>
  );
}
