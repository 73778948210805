import { connect } from "react-redux";
import component from "../../content/podcast/single";

const mapStateToProps = (state, ownProps) => ({
  loading: true,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(component);
