import React, { Component } from "react";
import QS from "query-string";
import { toast } from "react-toastify";
import Header from "../../components/header/home";
import PageLayout from "../account/layout";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";
import ProgressBar from "../../components/progress/indeterminate";
import Select from "../../components/form/select";
import TextArea from "../../components/form/textarea";
import Avatar from "../account/avatar";
import { classNames } from "../../helpers/classNames";
import { CATEGORY_OPTIONS } from "../../store/metadata";

class PageProfileCreate extends Component {
  constructor(props) {
    super(props);

    let parsedQuery = QS.parse(window.location.search);
    const { profile_id } = parsedQuery;

    this.state = {
      profile_id,
      photo_url: null,
      topic: "preach", // [preach,podcast]
      type: "episodic", // [serial,episodic]
      country_code: "cd",
      title: "",
      description: "",
      is_content_explicit: false,
      is_fund_enabled: false,
      primary_language: "en",
      primary_category_id: "none-selected",
      facebook_url: "",
      instagram_url: "",
      twitter_url: "",
      youtube_url: "",
      categories_ids: [],
    };
  }

  componentDidMount() {
    document.title = "Créer une prédication - Yoka.cd";

    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { createPodcastId } = this.props;
    if (createPodcastId !== prevState.createPodcastId) {
      if (createPodcastId)
        this.props.history.push(`/my-account/preachs/${createPodcastId}`);
    }
  }

  fetchData() {
    const { getCategories } = this.props;
    if (getCategories) getCategories();

    let categories_select = [];
    CATEGORY_OPTIONS.forEach((item) => {
      const { value, display } = item;
      categories_select.push({ value, label: display });
    });

    this.setState({ categories_select });
  }

  onCategorySelected(value) {
    let { categories_ids } = this.state;

    let index = categories_ids.findIndex((item) => item === value);
    if (index > 0) {
      categories_ids = categories_ids.filter((_, x) => x !== index);
    } else {
      categories_ids = [...categories_ids, value];
    }

    this.setState({ categories_ids });
  }

  onRequestSubmit() {
    const {
      profile_id,
      type,
      title,
      description,
      photo_url,
      country_code,
      primary_language,
      primary_category_id,
      facebook_url,
      instagram_url,
      twitter_url,
      youtube_url,
      categories_ids,
      is_content_explicit,
      is_fund_enabled,
    } = this.state;

    if (title === "" || description === "") {
      toast("Veuillez compléter tous les champs", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    let bodyParams = {
      topic: "preach",
      profile_id,
      title,
      type,
      description,
      country_code,
      photo_url,
      primary_language,
      primary_category_id,
      facebook_url,
      instagram_url,
      twitter_url,
      youtube_url,
      categories_ids,
      is_content_explicit,
      is_fund_enabled,
    };

    const { createPodcast } = this.props;
    if (createPodcast) createPodcast(bodyParams);
  }

  render() {
    const { categories_ids, primary_category_id } = this.state;
    const { loading, categories: categories_select } = this.props;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Créer votre prédication</h1>
            <hr className="my-5" />
            {loading && <ProgressBar />}
            <div className="flex">
              <div className="space-y-4 md:w-1/2">
                <TextField
                  readOnly={loading}
                  nameOrId="profile-name"
                  placeholder="Choisissez bien le nom de votre prédication"
                  label={"Nom de la prédication"}
                  onChange={(title) => this.setState({ title })}
                />
                <TextArea
                  label={"Description du prédication"}
                  placeholder="Quel est le sujet de votre prédication ?"
                  onChange={(description) => this.setState({ description })}
                />
                <Select
                  disabled={loading}
                  nameOrId="address-country"
                  label={"Pays ou Région"}
                  options={[{ label: "Congo - Kinshasa", value: "CD" }]}
                  onChange={(country_code) => this.setState({ country_code })}
                />
                <Select
                  disabled={loading}
                  nameOrId="podcast-language"
                  label={"Langue"}
                  options={[
                    { label: "Français", value: "fr" },
                    { label: "Anglais", value: "en" },
                  ]}
                  onChange={(primary_language) =>
                    this.setState({ primary_language })
                  }
                />
                <Select
                  disabled={loading}
                  nameOrId="podcast-category"
                  label={"Catégorie principal"}
                  options={[
                    { label: "Sélectionner", value: "none-selected" },
                    ...categories_select,
                  ]}
                  onChange={(primary_category_id) =>
                    this.setState({
                      primary_category_id,
                      categories_ids: [primary_category_id],
                    })
                  }
                />
                {primary_category_id !== "none-selected" && (
                  <div className="mt-5 space-y-3">
                    <span className="text-sm font-semibold text-gray-700">
                      Ajouter d'autres catégories
                    </span>
                    <div className="flex flex-wrap">
                      {categories_select
                        .filter((_, x) => x < 35)
                        .map((item, x) => (
                          <div
                            key={x}
                            onClick={() => this.onCategorySelected(item.value)}
                            className={classNames(
                              "md:mr-2 mb-2 cursor-pointer px-3 py-1 border-[1.5px] rounded-full",
                              categories_ids.includes(item.value)
                                ? " border-primary-400 bg-primary-400 text-white"
                                : " border-gray-400 "
                            )}
                          >
                            <span className="text-sm font-medium">
                              {item.label}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                <div className="py-5">
                  <hr className="" />
                </div>
                <TextField
                  readOnly={loading}
                  nameOrId="twitter_url"
                  label={"Lien page Twitter"}
                  placeholder="ex : https://twitter.com/pagexxxx"
                  onChange={(twitter_url) => this.setState({ twitter_url })}
                />
                <TextField
                  readOnly={loading}
                  nameOrId="instagram_url"
                  label={"Lien page instagram"}
                  placeholder="ex : https://instagram.com/pagexxxx"
                  onChange={(instagram_url) => this.setState({ instagram_url })}
                />
                <TextField
                  readOnly={loading}
                  nameOrId="youtube_url"
                  label={"Lien page youtube"}
                  placeholder="ex : https://youtube.com/pagexxxx"
                  onChange={(youtube_url) => this.setState({ youtube_url })}
                />
                <TextField
                  readOnly={loading}
                  nameOrId="facebook_url"
                  label={"Lien page instagram"}
                  placeholder="ex : https://facebook.com/pagexxxx"
                  onChange={(facebook_url) => this.setState({ facebook_url })}
                />
              </div>

              <div className="w-1/2">
                <div className="p-10 md:pt-6">
                  <Avatar
                    buttonText="Pochette"
                    image={this.state.photo_url}
                    imageUploaded={(photo_url) => {
                      this.setState({ photo_url });
                    }}
                  />
                </div>
              </div>
            </div>

            <hr className="my-5" />
            <div className="flex justify-between space-x-3">
              <div>
                <Button
                  disabled={loading}
                  iconLeft={null}
                  iconRight={null}
                  content="Annuler"
                  onClick={() => {
                    this.props.history.push("/my-account/library");
                  }}
                />
              </div>
              <div>
                <Button
                  iconLeft={null}
                  iconRight={null}
                  disabled={loading}
                  content="Créer votre podcast"
                  buttonStyle={"primary"}
                  onClick={() => this.onRequestSubmit()}
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageProfileCreate;
