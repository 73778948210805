import React, { Component } from "react";
import QS from "query-string";
import Header from "../../components/header/home";
import PageLayout from "../account/layout";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";
import ProgressBar from "../../components/progress/indeterminate";
import { toast } from "react-toastify";
import Select from "../../components/form/select";
import TextArea from "../../components/form/textarea";
import Avatar from "../account/avatar";

class PageProfileCreate extends Component {
  constructor(props) {
    super(props);

    let parsedQuery = QS.parse(window.location.search);
    const { type = "podcaster" } = parsedQuery;

    this.state = {
      photo_url: null,
      name: "",
      first_name: "",
      last_name: "",
      about_text: "",
      social_facebook: "",
      social_instagram: "",
      social_twitter: "",
      address_country: "CD",
      profile_type: "corporate",
      profile_kind: type,
    };
  }

  componentDidMount() {
    document.title = "Créer un profil - Yoka.cd";
    setTimeout(() => this.fetchProfile(), 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { createdProfileId } = this.props;
    if (createdProfileId !== prevState.createdProfileId) {
      if (createdProfileId)
        this.props.history.push(`/my-account/${createdProfileId}`);
    }
  }

  onRequestSubmit() {
    const {
      name,
      first_name,
      last_name,
      about_text,
      photo_url,
      address_country,
      social_facebook,
      social_instagram,
      social_twitter,
      profile_type,
      profile_kind,
    } = this.state;

    if (about_text === "") {
      toast("Les nouveaux mot de passe ne correspondent pas !", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    if (profile_type === "person") {
      if (first_name === "" || last_name === "") {
        toast("Veuillez compléter tous les champs", {
          type: "warning",
          position: "bottom-center",
        });
        return;
      }
    } else if (profile_type === "corporate") {
      if (name === "") {
        toast("Veuillez compléter tous les champs", {
          type: "warning",
          position: "bottom-center",
        });
        return;
      }
    }

    let bodyParams = {
      name,
      first_name,
      last_name,
      type: profile_type,
      kind: profile_kind,
      about_text,
      address_country,
      photo_url,
      social_facebook,
      social_instagram,
      social_twitter,
    };

    const { createProfile } = this.props;
    if (createProfile) createProfile(bodyParams);
  }

  render() {
    const { profile_kind, profile_type } = this.state;
    const { loading } = this.props;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Créer un profil</h1>
            <hr className="my-5" />
            {loading && <ProgressBar />}
            <div className="flex space-x-10">
              <div className="">
                <Avatar
                  buttonText="Choisir une photo"
                  image={this.state.photo_url}
                  imageUploaded={(photo_url) => {
                    this.setState({ photo_url });
                  }}
                />
              </div>
              <div className="space-y-3 md:w-[50%]">
                {/** <Select
                  nameOrId="profile-type"
                  label={"Type du profil"}
                  options={[
                    { label: "Particulier", value: "person" },
                    { label: "Business", value: "corporate" },
                  ]}
                  onChange={(profile_type) => this.setState({ profile_type })}
                /> */}
                {profile_type === "corporate" && (
                  <TextField
                    readOnly={loading}
                    nameOrId="profile-name"
                    placeholder="ex : John Wick"
                    label={"Nom de votre profil"}
                    onChange={(name) => this.setState({ name })}
                  />
                )}
                {profile_type === "person" && (
                  <div className="flex space-x-5">
                    <div className="flex-1">
                      <TextField
                        readOnly={loading}
                        nameOrId="first-name"
                        label={"Prenom"}
                        placeholder="ex : John"
                        onChange={(first_name) => this.setState({ first_name })}
                      />
                    </div>
                    <div className="flex-1">
                      <TextField
                        readOnly={loading}
                        nameOrId="last-name"
                        label={"Nom de famille"}
                        placeholder="ex : Wick"
                        onChange={(last_name) => this.setState({ last_name })}
                      />
                    </div>
                  </div>
                )}
                <TextArea
                  label={"A propos de votre profil"}
                  placeholder="Entrez une brève description de votre profil"
                  onChange={(about_text) => this.setState({ about_text })}
                />
                <div className="py-5">
                  <hr className="" />
                </div>
                <Select
                  disabled={loading}
                  nameOrId="address-country"
                  label={"Pays ou Région"}
                  options={[{ label: "Congo - Kinshasa", value: "CD" }]}
                  onChange={(address_country) =>
                    this.setState({ address_country })
                  }
                />
                <TextField
                  readOnly={loading}
                  nameOrId="social-facebook"
                  label={"Lien page facebook"}
                  placeholder="ex : https://fb.me/pagexxxx"
                  onChange={(social_facebook) =>
                    this.setState({ social_facebook })
                  }
                />
                <TextField
                  readOnly={loading}
                  nameOrId="social-twitter"
                  label={"Lien page facebook"}
                  placeholder="ex : https://twitter.com/pagexxxx"
                  onChange={(social_twitter) =>
                    this.setState({ social_twitter })
                  }
                />
                <TextField
                  readOnly={loading}
                  nameOrId="social-twitter"
                  label={"Lien page instagram"}
                  placeholder="ex : https://instagram.com/pagexxxx"
                  onChange={(social_instagram) =>
                    this.setState({ social_instagram })
                  }
                />
              </div>
            </div>

            <hr className="my-5" />
            <div className="flex justify-end space-x-3">
              <div>
                <Button
                  disabled={loading}
                  iconLeft={null}
                  iconRight={null}
                  content="Annuler"
                  onClick={() => {
                    this.props.history.push("/my-account");
                  }}
                />
              </div>
              <div>
                <Button
                  iconLeft={null}
                  iconRight={null}
                  disabled={loading}
                  content="Créer le profil"
                  buttonStyle={"primary"}
                  onClick={() => this.onRequestSubmit()}
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageProfileCreate;
