/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from "react";
import { toast } from "react-toastify";
import Plyr from "plyr";
import {
  LibraryIcon,
  MicrophoneIcon,
  UploadIcon,
  XIcon,
} from "@heroicons/react/outline";

import { signedUrlChunk } from "../../api/podcastService";
import { classNames } from "../../helpers/classNames";
import { fileToBlob } from "../../utils/FileUtil";

import Button from "../../components/form/button";
import Chunk from "../../helpers/Chunk";
import ProgressBar from "../../components/progress/indeterminate";
import TextField from "../../components/form/input";
import TextArea from "../../components/form/textarea";
import RadioGroup from "../../components/form/radiogroup";

class EpisodeInfo extends Component {
  constructor(props) {
    super(props);
    const { episode } = props;
    this.state = {
      fileUploading: false,
      textUploading: "Aperçu de votre fichier",
      audioUrl: null,
      audioFile: null,
      audioDuration: 0,
      audioBlobUrl: null,
      audioMetadata: null,
      showPicker: false,
      activeTab: "UPLOAD_AUDIO",

      epTitle: episode.title,
      epDescription: episode.description,
      epSeasonNumber: episode.season_number,
      epNumber: episode.number,
      epExplicit: episode.is_content_explicit,
      epType: episode.type || "episode",
      epPublished: episode.is_published || false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.initPlayer(), 400);
  }

  initPlayer() {
    let playerElement = document.getElementById("player-preview");
    if (playerElement) {
      this.player = new Plyr(playerElement);
    }
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  onFileChange = (event) => {
    let files = event.target.files;
    const [audio_file] = files;

    let audio = {
      name: audio_file.name,
      type: audio_file.type,
      size: audio_file.size,
    };

    let audioBlobUrl = fileToBlob(audio_file);
    console.log("audioBlobUrl:", audioBlobUrl);

    let audioElement = document.createElement("audio");
    audioElement.src = audioBlobUrl;
    audioElement.addEventListener(
      "loadedmetadata",
      () => {
        var audioDuration = audioElement.duration;
        this.setState({ audioDuration });
      },
      false
    );

    this.setState(
      { audioBlobUrl, audioMetadata: audio, audioFile: audio_file },
      () => {
        // generate_signed_url
        this.generate_signed_url(audio);

        // reset the file input fields
        let picker = document.getElementById("library-picker");
        if (picker) picker.value = null;
      }
    );
  };

  generate_signed_url(audio) {
    let body = { filename: audio.name, type: audio.type };
    signedUrlChunk(body, {})
      .then((result) => {
        let response = result.data;
        const { signedUrl } = response;

        if (signedUrl) {
          this.setState({ signedRequestPayload: response }, () =>
            this.upload_to_signed_url()
          );
        } else {
          throw new Error("Invalid signed");
        }
      })
      .catch((err) => {
        toast("Unable to generate signed", {
          type: "error",
          position: "top-center",
        });
      });
  }

  upload_to_signed_url() {
    const { audioFile, signedRequestPayload } = this.state;
    const { signedUrl, fileName } = signedRequestPayload;

    console.log("signedUrl:", signedUrl);

    this.setState({
      fileUploading: true,
      textUploading: "Téléchargement en cours ...",
    });
    const chunk = new Chunk({
      filename: fileName,
      name: "file", // request name
      size: 500019, // chunk size
      url: signedUrl, // destination
      onProgress: (progress) => {
        console.log(`${progress}% uploaded...`);
      },
      onError: (error) => {
        console.log(`error occured : ${error}`);
        this.setState({ fileUploading: false });
        toast("Téléchargement échoué", { type: "error" });
      },
      onFinished: (data) => {
        console.log(`finished : ${data}`);
        toast("Téléchargement réussi", { type: "success" });

        const { file } = data;
        this.setState({
          audioUrl: file.path,
          fileUploading: false,
          textUploading: "Téléchargement réussi",
        });
      },
    });
    chunk.setFile(audioFile);
    chunk.commit();
  }

  onRequestSubmit() {
    const {
      epTitle,
      epDescription,
      epExplicit,
      epType,
      epNumber,
      epSeasonNumber,
      epPublished,
      audioDuration,
      audioUrl,
      audioFile,
      signedRequestPayload,
    } = this.state;

    if (epTitle === "" || epDescription === "") {
      toast("Veuillez remplir tous les champs réquis", {
        position: "bottom-center",
        type: "error",
      });
      return;
    }

    let bodyRequest = {
      audio: null,
      type: epType,
      title: epTitle,
      description: epDescription,
      is_content_explicit: epExplicit,
      number: epNumber !== "" ? epNumber : null,
      season_number: epSeasonNumber !== "" ? epSeasonNumber : null,
      is_published: epPublished,
    };

    if (audioFile) {
      bodyRequest.audio = {
        url: audioUrl,
        type: audioFile.type,
        duration: audioDuration,
        filename: signedRequestPayload.fileName,
      };
    }

    const { editEpisode, episode } = this.props;
    if (editEpisode) editEpisode(episode.id, { ...bodyRequest });
  }

  render() {
    const { episode } = this.props;
    const {
      activeTab,
      fileUploading,
      textUploading,
      audioBlobUrl,
      epTitle,
      showPicker,
    } = this.state;
    return (
      <div>
        <div className="flex space-x-2 justify-between">
          <h1 className="text-4xl font-bold">Détails episode</h1>
          <a href="javascript:void(0);" onClick={() => this.onRequestClose()}>
            <XIcon className="w-10 h-10 text-gray-400" />
          </a>
        </div>
        <hr className="my-5" />
        <div className="py-5 flex space-x-10">
          <div className="w-full px-5 relative">
            <div className="flex space-x-5">
              <div className="w-[40%]">
                <div className="border-[0.5px] px-10 py-5 rounded">
                  {fileUploading && <ProgressBar />}
                  <h1>{textUploading}</h1>
                  <hr className="my-5" />
                  {epTitle && (
                    <h1 className="font-bold text-xl mb-3">{epTitle}</h1>
                  )}
                  <audio
                    controls={true}
                    id={"player-preview"}
                    style={{ width: "100%" }}
                  >
                    <source src={episode.url} type="audio/mp3" />
                  </audio>
                </div>
                <div className="flex justify-end pt-5">
                  <div>
                    <Button
                      disabled={fileUploading}
                      iconLeft={null}
                      iconRight={null}
                      content="Remplacer l'audio"
                      onClick={() => {
                        let picker = document.getElementById("library-picker");
                        if (picker) picker.click();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-[60%]">
                <h1 className="font-semibold text-2xl">
                  Informations sur l'épisode
                </h1>
                <p>
                  Ajoutez des informations et définissez la date de publication.
                </p>
                <div className="my-5 space-y-3">
                  <TextField
                    nameOrId="episode-name"
                    label={"Titre de l'épisode *"}
                    placeholder="Titre"
                    defaultValue={episode.title}
                    onChange={(epTitle) => this.setState({ epTitle })}
                  />
                  <TextArea
                    nameOrId="episode-description"
                    label={"Description de l'épisode *"}
                    placeholder="Entrez une brève description"
                    defaultValue={episode.description}
                    onChange={(epDescription) =>
                      this.setState({ epDescription })
                    }
                  />
                  <RadioGroup
                    label={"Votre épisode comporte-t-il du contenu explicite ?"}
                    nameOrId="explicit-content"
                    options={[
                      {
                        label: "Oui",
                        value: "include_explicit_content",
                        checked: episode.is_content_explicit === true,
                        onChecked: () => this.setState({ epExplicit: true }),
                      },
                      {
                        label: "Non",
                        value: "no_explicit_content",
                        checked: episode.is_content_explicit === false,
                        onChecked: () => this.setState({ epExplicit: false }),
                      },
                    ]}
                  />
                  <RadioGroup
                    label={"Type d'épisode"}
                    nameOrId="episode-type"
                    options={[
                      {
                        label: "Complet",
                        value: "episode",
                        checked: episode.type === "episode",
                        onChecked: () => this.setState({ epType: "episode" }),
                      },
                      {
                        label: "Bande-annonce",
                        value: "trailer",
                        checked: episode.type === "trailer",
                        onChecked: () => this.setState({ epType: "trailer" }),
                      },
                      {
                        label: "Bonus",
                        value: "bonus",
                        checked: episode.type === "bonus",
                        onChecked: () => this.setState({ epType: "bonus" }),
                      },
                    ]}
                  />
                  <div className="hidden space-x-5">
                    <TextField
                      type="number"
                      nameOrId="episode-season"
                      label="Numéro de la saison"
                      placeholder={"ex : 1"}
                      defaultValue={episode.season_number}
                      onChange={(epSeasonNumber) =>
                        this.setState({ epSeasonNumber })
                      }
                    />
                    <TextField
                      type="number"
                      nameOrId="episode-number"
                      label="Numéro de l'épisode"
                      placeholder={"ex : 5"}
                      defaultValue={episode.number}
                      onChange={(epNumber) => this.setState({ epNumber })}
                    />
                  </div>

                  <RadioGroup
                    label={"Disponibilité dans Yoka"}
                    nameOrId="publish-state"
                    options={[
                      {
                        label: "Publié",
                        value: "is_published",
                        checked: episode.is_published === true,
                        onChecked: () => this.setState({ epPublished: true }),
                      },
                      {
                        label: "Brouillon",
                        value: "is_not_published",
                        checked: episode.is_published === false,
                        onChecked: () => this.setState({ epPublished: false }),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="flex justify-between pt-2">
              <div>
                <Button
                  disabled={fileUploading}
                  iconLeft={null}
                  iconRight={null}
                  content="Quitter"
                  onClick={() => this.onRequestClose()}
                />
              </div>
              <div>
                <Button
                  buttonStyle={"primary"}
                  disabled={fileUploading}
                  iconLeft={null}
                  iconRight={null}
                  content="Enregistrer les modifications"
                  onClick={() => this.onRequestSubmit()}
                />
              </div>
            </div>

            {showPicker && (
              <div className="absolute inset-0 flex space-x-4 bg-white">
                <div className="space-y-4 flex flex-col">
                  {[
                    {
                      key: "UPLOAD_AUDIO",
                      title: "Uploader un audio",
                      leftIcon: <UploadIcon className="w-5 h-5" />,
                      onClick: () =>
                        this.setState({ activeTab: "UPLOAD_AUDIO" }),
                    },
                    {
                      key: "RECORD_AUDIO",
                      title: "Enregistrer un audio",
                      leftIcon: <MicrophoneIcon className="w-5 h-5" />,
                      onClick: () =>
                        this.setState({ activeTab: "RECORD_AUDIO" }),
                    },
                    /*{
                      key: "LIBRARY_AUDIO",
                      title: "Choisir depuis la bibliothèque",
                      leftIcon: <LibraryIcon className="w-5 h-5" />,
                      onClick: () =>
                        this.setState({ activeTab: "LIBRARY_AUDIO" }),
                    },*/
                  ].map((item, x) => (
                    <a href="javascript:void(0);" onClick={item.onClick}>
                      <div
                        className={classNames(
                          "w-[19rem] p-10 py-4 border-[1px] border-gray-300 rounded flex items-center space-x-3",
                          activeTab === item.key && " bg-slate-200"
                        )}
                      >
                        {item.leftIcon}
                        <h1 className="font-medium text-sm">{item.title}</h1>
                      </div>
                    </a>
                  ))}
                </div>
                <div className="w-full border-l">
                  {activeTab === "UPLOAD_AUDIO" && (
                    <div className="px-10 flex space-x-5">
                      <div className="w-full space-y-3">
                        <h1 className="text-2xl font-bold">
                          Importer des fichiers audio ou vidéo
                        </h1>
                        <p>Créer votre épisode audio en quelques étapes</p>
                        <p>
                          Type des fichiers pris en charge
                          <ul className="list-outside text-gray-400">
                            <li>Fichier audio : mp3, m4a, wav ou mpg</li>
                          </ul>
                        </p>
                      </div>
                      <div className="w-1/2">
                        <div className="flex flex-col items-center justify-center border border-dashed border-gray-400 rounded-md h-[30rem] p-10 space-y-5">
                          <UploadIcon className="w-24 h-24 text-gray-400" />
                          <Button
                            buttonStyle={"primary"}
                            content="Sélectionner un fichier"
                            iconLeft={null}
                            iconRight={null}
                            onClick={() => {
                              let picker =
                                document.getElementById("library-picker");
                              if (picker) picker.click();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === "RECORD_AUDIO" && (
                    <div className="flex-1">
                      <div className="px-10">
                        <div className="border border-dashed border-gray-400 rounded-md h-[30rem]">
                          <div className="p-10 space-y-5">
                            <h1 className="text-2xl font-bold text-center">
                              Enregistrer votre premier episode
                            </h1>
                            <Button
                              buttonStyle={"primary"}
                              content="Débuter l'enregistrement"
                              iconLeft={null}
                              iconRight={null}
                            />

                            <p>
                              Si vous n'avez aucun fichier à uploader, vous
                              pouvez enregistrer votre premier episode depuis
                              votre navigateur
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <input
          type="file"
          id={"library-picker"}
          accept=".wav,.mp3,.mp4"
          className="hidden"
          onChange={this.onFileChange}
        />
      </div>
    );
  }
}

export default EpisodeInfo;
