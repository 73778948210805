/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from "react";
import { toast } from "react-toastify";
import Plyr from "plyr";
import {
  LibraryIcon,
  MicrophoneIcon,
  UploadIcon,
  XIcon,
} from "@heroicons/react/outline";

import { signedUrlChunk } from "../../api/assetService";
import { classNames } from "../../helpers/classNames";
import { fileToBlob } from "../../utils/FileUtil";

import Button from "../../components/form/button";
import Chunk from "../../helpers/Chunk";
import ProgressBar from "../../components/progress/indeterminate";
import TextField from "../../components/form/input";
import TextArea from "../../components/form/textarea";
import RadioGroup from "../../components/form/radiogroup";

class EpisodeWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploading: false,
      textUploading: "Téléchargement en cours ...",
      audioUrl: null,
      audioFile: null,
      audioDuration: 0,
      audioBlobUrl: null,
      audioMetadata: null,
      wizardMode: "UPLOAD",
      activeTab: "UPLOAD_AUDIO",

      trackTitle: "",
      trackDescription: "",
      diskNumber: "",
      trackNumber: "",
      isContentExplicit: false,
      trackType: "track",
    };
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  onFileChange = (event) => {
    let files = event.target.files;
    const [audio_file] = files;

    let audio = {
      name: audio_file.name,
      type: audio_file.type,
      size: audio_file.size,
    };

    let audioBlobUrl = fileToBlob(audio_file);
    console.log("audioBlobUrl:", audioBlobUrl);

    let audioElement = document.createElement("audio");
    audioElement.src = audioBlobUrl;
    audioElement.addEventListener(
      "loadedmetadata",
      () => {
        var audioDuration = audioElement.duration;
        this.setState({ audioDuration });
      },
      false
    );

    this.setState(
      { audioBlobUrl, audioMetadata: audio, audioFile: audio_file },
      () => {
        // generate_signed_url
        this.generate_signed_url(audio);

        // reset the file input fields
        let picker = document.getElementById("library-picker");
        if (picker) picker.value = null;

        let playerElement = document.getElementById("player-preview");
        if (playerElement) {
          const player = new Plyr(playerElement);
        }
      }
    );
  };

  generate_signed_url(audio) {
    let body = { filename: audio.name, type: audio.type };
    signedUrlChunk(body, {})
      .then((result) => {
        let response = result.data;
        const { signedUrl } = response;

        if (signedUrl) {
          this.setState({ signedRequestPayload: response }, () =>
            this.upload_to_signed_url()
          );
        } else {
          throw new Error("Invalid signed");
        }
      })
      .catch((err) => {
        toast("Unable to generate signed", {
          type: "error",
          position: "top-center",
        });
      });
  }

  upload_to_signed_url() {
    const { audioFile, signedRequestPayload } = this.state;
    const { signedUrl, fileName } = signedRequestPayload;

    console.log("signedUrl:", signedUrl);

    this.setState({ fileUploading: true });
    const chunk = new Chunk({
      filename: fileName,
      name: "file", // request name
      size: 500019, // chunk size
      url: signedUrl, // destination
      onProgress: (progress) => {
        console.log(`${progress}% uploaded...`);
      },
      onError: (error) => {
        console.log(`error occured : ${error}`);
        this.setState({ fileUploading: false });
        toast("Téléchargement échoué", { type: "error" });
      },
      onFinished: (data) => {
        console.log(`finished : ${data}`);
        toast("Téléchargement réussi", { type: "success" });

        const { file } = data;
        this.setState({
          audioUrl: file.path,
          fileUploading: false,
          textUploading: "Téléchargement réussi",
        });
      },
    });
    chunk.setFile(audioFile);
    chunk.commit();
  }

  onRequestSubmit() {
    const {
      trackTitle,
      trackDescription,
      isContentExplicit,
      trackType,
      trackNumber,
      diskNumber,
      audioDuration,
      audioUrl,
      audioFile,
      signedRequestPayload,
    } = this.state;

    if (trackTitle === "" || trackDescription === "") {
      toast("Veuillez remplir tous les champs réquis", {
        position: "bottom-center",
        type: "error",
      });
      return;
    }

    let bodyRequest = {
      audio: {
        url: audioUrl,
        type: audioFile.type,
        duration: audioDuration,
        filename: signedRequestPayload.fileName,
      },
      title: trackTitle,
      description: trackDescription,
      is_content_explicit: isContentExplicit,
      type: trackType,
      track_number: trackNumber !== "" ? trackNumber : null,
      disk_number: diskNumber !== "" ? diskNumber : null,
    };

    const { createTrack, releaseId } = this.props;
    if (createTrack) createTrack({ ...bodyRequest, release_id: releaseId });
  }

  render() {
    const { activeTab, audioFile, fileUploading, textUploading, audioBlobUrl } =
      this.state;
    return (
      <div>
        <div className="flex space-x-2 justify-between">
          <h1 className="text-4xl font-bold">Nouvelle piste</h1>
          <a href="javascript:void(0);" onClick={() => this.onRequestClose()}>
            <XIcon className="w-10 h-10 text-gray-400" />
          </a>
        </div>
        <hr className="my-5" />
        <div className="py-5 flex space-x-10">
          <div className="space-y-4 flex flex-col">
            {[
              {
                key: "UPLOAD_AUDIO",
                title: "Uploader un audio",
                leftIcon: <UploadIcon className="w-5 h-5" />,
                onClick: () => this.setState({ activeTab: "UPLOAD_AUDIO" }),
              },
              {
                key: "RECORD_AUDIO",
                title: "Enregistrer un audio",
                leftIcon: <MicrophoneIcon className="w-5 h-5" />,
                onClick: () => this.setState({ activeTab: "RECORD_AUDIO" }),
              },
              /*{
                key: "LIBRARY_AUDIO",
                title: "Choisir depuis la bibliothèque",
                leftIcon: <LibraryIcon className="w-5 h-5" />,
                onClick: () => this.setState({ activeTab: "LIBRARY_AUDIO" }),
              },*/
            ].map((item, x) => (
              <a href="javascript:void(0);" onClick={item.onClick}>
                <div
                  className={classNames(
                    "w-[19rem] p-10 py-4 border-[1px] border-gray-300 rounded flex items-center space-x-3",
                    activeTab === item.key && " bg-slate-200"
                  )}
                >
                  {item.leftIcon}
                  <h1 className="font-medium text-sm">{item.title}</h1>
                </div>
              </a>
            ))}
          </div>
          <div className="w-full border-l px-5">
            {audioFile ? (
              <>
                <div className="flex space-x-5">
                  <div className="w-1/2">
                    <h1 className="font-semibold text-2xl">
                      Informations sur la piste
                    </h1>
                    <p>
                      Ajoutez des informations et définissez la date de
                      publication.
                    </p>
                    <div className="my-5 space-y-3">
                      <TextField
                        nameOrId="episode-name"
                        label={"Titre de la piste *"}
                        placeholder="Titre"
                        onChange={(trackTitle) => this.setState({ trackTitle })}
                      />
                      <TextArea
                        nameOrId="episode-description"
                        label={"Description de la piste *"}
                        placeholder="Entrez une brève description"
                        onChange={(trackDescription) =>
                          this.setState({ trackDescription })
                        }
                      />
                      <RadioGroup
                        label={
                          "Votre épisode comporte-t-il du contenu explicite ?"
                        }
                        nameOrId="explicit-content"
                        options={[
                          {
                            label: "Oui",
                            value: "include_explicit_content",
                            onChecked: () =>
                              this.setState({ isContentExplicit: true }),
                          },
                          {
                            label: "Non",
                            value: "no_explicit_content",
                            checked: true,
                            onChecked: () =>
                              this.setState({ isContentExplicit: false }),
                          },
                        ]}
                      />
                      <RadioGroup
                        label={"Type d'épisode"}
                        nameOrId="episode-type"
                        options={[
                          {
                            label: "Complet",
                            value: "episode",
                            checked: true,
                            onChecked: () =>
                              this.setState({ trackType: "episode" }),
                          },
                          {
                            label: "Bande-annonce",
                            value: "trailer",
                            onChecked: () =>
                              this.setState({ trackType: "trailer" }),
                          },
                          {
                            label: "Bonus",
                            value: "bonus",
                            onChecked: () => this.setState({ trackType: "bonus" }),
                          },
                        ]}
                      />
                      <div className="flex space-x-5">
                        <TextField
                          type="number"
                          nameOrId="episode-season"
                          label="Numéro de la saison"
                          placeholder={"ex : 1"}
                          onChange={(diskNumber) =>
                            this.setState({ diskNumber })
                          }
                        />
                        <TextField
                          type="number"
                          nameOrId="episode-number"
                          label="Numéro de la piste"
                          placeholder={"ex : 5"}
                          onChange={(trackNumber) => this.setState({ trackNumber })}
                        />
                      </div>
                      <hr />
                      <div className="md:w-[50%] pt-2">
                        <Button
                          type={"primary"}
                          disabled={fileUploading}
                          iconLeft={null}
                          iconRight={null}
                          content="Enregistrer la piste"
                          onClick={() => this.onRequestSubmit()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="border-[0.5px] px-10 py-5 rounded">
                      {fileUploading && <ProgressBar />}
                      <h1>{textUploading}</h1>
                      <hr className="my-5" />
                      {/** {trackTitle && (
                        <h1 className="font-bold text-xl mb-3">{trackTitle}</h1>
                      )} */}
                      <audio
                        controls={true}
                        id={"player-preview"}
                        style={{ width: "100%" }}
                      >
                        <source src={audioBlobUrl} type="audio/mp3" />
                      </audio>
                    </div>
                    <div className="md:w-[50%] pt-5">
                      <Button
                        disabled={fileUploading}
                        iconLeft={null}
                        iconRight={null}
                        content="Remplacer"
                        onClick={() => {
                          let picker =
                            document.getElementById("library-picker");
                          if (picker) picker.click();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {activeTab === "UPLOAD_AUDIO" && (
                  <div className="px-10 flex space-x-5">
                    <div className="w-full space-y-3">
                      <h1 className="text-2xl font-bold">
                        Importer des fichiers audio ou vidéo
                      </h1>
                      <p>Créer votre épisode audio en quelques étapes</p>
                      <p>
                        Type des fichiers pris en charge
                        <ul className="list-outside text-gray-400">
                          <li>Fichier audio : mp3, m4a, wav ou mpg</li>
                        </ul>
                      </p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-col items-center justify-center border border-dashed border-gray-400 rounded-md h-[30rem] p-10 space-y-5">
                        <UploadIcon className="w-24 h-24 text-gray-400" />
                        <Button
                          buttonStyle={"primary"}
                          content="Sélectionner un fichier"
                          iconLeft={null}
                          iconRight={null}
                          onClick={() => {
                            let picker =
                              document.getElementById("library-picker");
                            if (picker) picker.click();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "RECORD_AUDIO" && (
                  <div className="flex-1">
                    <div className="px-10">
                      <div className="border border-dashed border-gray-400 rounded-md h-[30rem]">
                        <div className="p-10 space-y-5">
                          <h1 className="text-2xl font-bold text-center">
                            Enregistrer votre premier episode
                          </h1>
                          <Button
                            buttonStyle={"primary"}
                            content="Débuter l'enregistrement"
                            iconLeft={null}
                            iconRight={null}
                          />

                          <p>
                            Si vous n'avez aucun fichier à uploader, vous pouvez
                            enregistrer votre premier episode depuis votre
                            navigateur
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <input
          type="file"
          id={"library-picker"}
          accept=".wav,.mp3,.mp4"
          className="hidden"
          onChange={this.onFileChange}
        />
      </div>
    );
  }
}

export default EpisodeWizard;
