import { toast } from "react-toastify";
import { userLogin, userCreate } from "../api/userService";
import { setCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "SESSION_SET_VALUE",
  propKey,
  propValue,
});

export const loginUser = (bodyParams) => (dispatch, getState) => {
  const state = getState();
  const { live } = state;

  dispatch(setKeyValue("loading", true));
  userLogin(bodyParams)
    .then((result) => {
      dispatch(setKeyValue("loading", false));

      let response = result.data;
      const { access_token, associated_user, error_type, error_message } =
        response;
      if (access_token) {
        setCookie("access_token", access_token);
        setCookie("account_id", associated_user.id);
        setCookie("account_username", associated_user.username);
        setCookie("account_name", associated_user.name);
        setCookie("account_photo_url", associated_user.photo_url);
        setCookie("account_email_address", associated_user.email_address);
        setCookie("profile_id", associated_user.profile_id);

        window.location.replace("/my-account");
      } else if (error_type) {
        toast(error_message, { type: "error", position: "bottom-center" });
      } else {
        throw new Error("error");
      }
    })
    .catch((err) => {
      dispatch(setKeyValue("loading", false));
      toast("Echec de connexion", { type: "error", position: "bottom-center" });
    });
};

export const createUser = (bodyParams) => (dispatch, getState) => {
  dispatch(setKeyValue("loading", true));
  userCreate(bodyParams)
    .then((result) => {
      dispatch(setKeyValue("loading", false));

      let response = result.data;
      const { access_token, associated_user } = response;
      if (access_token) {
        setCookie("access_token", access_token);
        setCookie("account_id", associated_user.id);
        setCookie("account_username", associated_user.username);
        setCookie("account_name", associated_user.name);
        setCookie("account_photo_url", associated_user.photo_url);
        setCookie("account_email_address", associated_user.email_address);
        setCookie("profile_id", associated_user.profile_id);

        window.location.replace("/my-account");
      } else {
        throw new Error("error");
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(setKeyValue("loading", false));
      toast("Echec lors de la création du compte", {
        type: "error",
        position: "bottom-center",
      });
    });
};
