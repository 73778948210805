import React, { Component } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header/home";
import DataList from "../../components/list/horizontal";
import DataListVertical from "../../components/list/vertical";
import Podcast from "../../components/podcast/itemHorizontal";
import HeroApps from "../../components/hero/apps";
import Profile from "../../components/profile/podcaster";
import PodcastHeader from "../../components/podcast/header";
import Episode from "../episode/item";

const profiles = [
  {
    id: "id-0001",
    name: "Yan Oleko",
    href: "/podcasts/profile/10000001",
    verified: true,
    images: {
      cover: require("../../../assets/img/profiles/ceo_image.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Eric DuBois",
    href: "/podcasts/profile/10000002",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Soraya Odia",
    href: "/podcasts/profile/10000002",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Joe Bassay",
    href: "/podcasts/profile/10000002",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover.jpg"),
    },
  },
];

const podcasts = [
  {
    id: "id-0001",
    name: "Monday Podcast",
    href: "/podcasts/podcast/00001",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Why I Run",
    href: "/podcasts/podcast/00002",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-02.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Emotions",
    href: "/podcasts/podcast/00003",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-03.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Change my life",
    href: "/podcasts/podcast/00004",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-04.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Les couilles sur la table",
    href: "/podcasts/podcast/00005",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-05.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Impower",
    href: "/podcasts/podcast/00006",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-06.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Impower",
    href: "/podcasts/podcast/00006",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-06.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Impower",
    href: "/podcasts/podcast/00006",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-06.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Change my life",
    href: "/podcasts/podcast/00004",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-04.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Change my life",
    href: "/podcasts/podcast/00004",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-04.jpg"),
    },
  },
];

const episodes = [
  {
    id: "id-0001",
    name: "Monday Podcast",
    href: "/podcasts/podcast/00001",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Why I Run",
    href: "/podcasts/podcast/00002",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-02.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Emotions",
    href: "/podcasts/podcast/00003",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-03.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Change my life",
    href: "/podcasts/podcast/00004",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-04.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Les couilles sur la table",
    href: "/podcasts/podcast/00005",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-05.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Impower",
    href: "/podcasts/podcast/00006",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-06.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Impower",
    href: "/podcasts/podcast/00006",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-06.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Impower",
    href: "/podcasts/podcast/00006",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-06.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Change my life",
    href: "/podcasts/podcast/00004",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-04.jpg"),
    },
  },
  {
    id: "id-0001",
    name: "Change my life",
    href: "/podcasts/podcast/00004",
    verified: true,
    images: {
      cover: require("../../../assets/img/covers/sample-cover-04.jpg"),
    },
  },
];

class PageProfile extends Component {
  render() {
    const { categoryName = "Category Name" } = this.props;
    return (
      <div className="h-full bg-white">
        <Header selectedIndex={3} />
        <PodcastHeader
          profileName="RTL"
          description="If you would like a more in-depth guide, check out my full video tutorial on YouTube, An Object Is A."
        />
        <div className="flex">
          <div className="w-7/12">
            <DataListVertical
              items={episodes}
              listTitle={`Episodes dans "${categoryName}"`}
              renderItem={({ item, x }) => (
                <Episode
                  key={x}
                  name={item.name}
                  image={item.images.cover}
                  linkTo={item.href}
                />
              )}
            />
          </div>
        </div>
        <DataList
          listTitle="Ces profiles peuvent vous intéresser"
          items={profiles}
          renderItem={({ item, x }) => (
            <Profile
              key={x}
              name={item.name}
              image={item.cover}
              linkTo={item.href}
            />
          )}
        />
        <DataList
          items={podcasts}
          listTitle={`Podcasts en collaboration`}
          renderItem={({ item, x }) => (
            <Podcast
              key={x}
              name={item.name}
              image={item.images.cover}
              linkTo={item.href}
            />
          )}
        />
        <HeroApps />
        <Footer />
      </div>
    );
  }
}

export default PageProfile;
