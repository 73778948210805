/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import HomeHeader from "../../components/header/home";
import SliderMain from "../../components/hero/apps";
import FooterPage from "../../components/footer";
import Button from "../../components/form/button";
import { ExternalLinkIcon } from "@heroicons/react/outline";

class HomePage extends Component {
  componentDidMount() {
    document.title = "Accueil";
  }

  render() {
    return (
      <div className="h-full">
        <HomeHeader />
        <section className="h-[70vh] relative">
          <img
            alt="cover"
            className="h-full w-full object-cover"
            src={require("../../../assets/img/misc/photo-music-section-3.jpeg")}
          />
          <div className="backdrop-blur-sm bg-dark-500/20 absolute inset-0" />

          <div className="absolute inset-0">
            <div className="container mx-auto h-full flex items-center">
              <div className="md:w-1/2">
                <h1 className="text-white text-6xl leading-[5rem] font-bold uppercase">
                  N'importe où, peu importe ce que vous voulez écouter, Vous
                  êtes au bon endroit
                </h1>

                <div className="flex space-x-5 mt-10">
                  <div>
                    <a
                      href="https://www.yoka.cd"
                      target="_blank"
                      rel="noreferrer"
                      className="px-6 py-3 rounded-full text-primary-500 border border-primary-500 bg-white hover:bg-primary-500 hover:text-white flex items-center space-x-3"
                    >
                      <span className="font-semibold">
                        Télécharger l'application mobile
                      </span>
                      <ExternalLinkIcon className="w-5 h-5" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto flex flex-col md:flex-row space-x-10 items-center">
            <div className="w-1/2 h-[40rem] overflow-hidden">
              <img
                alt="cover"
                className="h-full w-full object-cover"
                src={require("../../../assets/img/misc/photo-music-section-1.webp")}
              />
            </div>
            <div className="w-1/2 space-y-5">
              <h1 className="text-5xl font-bold">
                ÉCOUTEZ <span className="text-secondary-500">PARTOUT</span>
              </h1>
              <p className="text-xl">
                Profitez de votre audio exactement où, quand et comment vous le
                voulez. <br />
                Yoka est disponible sur centaines des appareils portables et
                pour la domicile et voiture, et aussi compatible avec Amazon
                Echo et Google Home.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto flex flex-col md:flex-row space-x-10 items-center py-20">
            <div className="w-1/2 space-y-5">
              <h1 className="text-5xl font-bold">
                VENDEZ VOTRE MUSIQUE
                <br />
                <span className="text-secondary-500">EN LIGNE</span>
              </h1>
              <p className="text-xl">
                Il n'a jamais été aussi simple de diffuser votre musique sur les
                magasins et les services de streaming, et de commencer à gagner
                de l'argent grâce à votre musique. Cliquez sur le bouton
                ci-dessous pour commencer :
              </p>
              <div className="flex">
                <a
                  href="https://dashboard.yoka.cd"
                  target="_blank"
                  rel="noreferrer"
                  className="px-6 py-3 rounded-full text-primary-500 border border-primary-500 bg-white hover:bg-primary-500 hover:text-white flex items-center space-x-3"
                >
                  <span className="font-semibold">Ajouter votre musique</span>
                  <ExternalLinkIcon className="w-5 h-5" />
                </a>
              </div>
            </div>
            <div className="w-1/2 h-[30rem] overflow-hidden">
              <img
                alt="cover"
                className="h-full w-full object-cover"
                src={require("../../../assets/img/misc/photo-music-section-4.jpeg")}
              />
            </div>
          </div>
        </section>
        <SliderMain targetText={"votre musique"} />
        <FooterPage />
      </div>
    );
  }
}

export default HomePage;
