import React, { useState } from "react";
import { UploadIcon } from "@heroicons/react/outline";
import ProgressCircular from "../../components/progress/circular";
import { assetUpload } from "../../api/userService";
import { classNames } from "../../helpers/classNames";

const DEFAULT_IMAGE = require("../../../assets/img/profiles/photo_empty_image.png");

export default function Avatar({
  buttonText = "Uploader",
  image = null,
  imageUploaded = null,
}) {
  const [loading, setLoading] = useState(false);

  const onFileChange = (ev) => {
    let files = ev.target.files;
    let [fileSelected] = files;

    let body = new FormData();
    body.append("photo", fileSelected);

    setLoading(true);
    assetUpload(body)
      .then((result) => {
        setLoading(false);

        let response = result.data;
        console.log("assetUpload [response]", response);

        const { asset } = response;
        if (asset) {
          if (imageUploaded) imageUploaded(asset.url);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log("assetUpload [err]", err);
      });
  };

  return (
    <div className="h-[15rem] w-[15rem] relative rounded-lg overflow-hidden">
      <img
        src={image || DEFAULT_IMAGE}
        alt={"Cover"}
        className={"w-full h-full object-cover"}
      />

      <div className="absolute bottom-0 right-0 left-0">
        <button
          className="w-full block bg-primary-200"
          onClick={() => {
            let picker = document.getElementById("file-picker");
            if (picker) picker.click();
          }}
        >
          <div className="flex items-center justify-center px-2 py-3 text-primary-500">
            <span className="text-md font-bold">{buttonText}</span>{" "}
            <UploadIcon className="w-6 h-6 ml-4" />
          </div>
        </button>
        <input
          type="file"
          id="file-picker"
          className="hidden"
          accept="image/pjpeg,image/jpeg,image/png"
          onChange={onFileChange}
        />
      </div>
      {loading && (
        <div className="absolute inset-0 flex flex-col justify-center">
          <ProgressCircular />
        </div>
      )}
    </div>
  );
}
