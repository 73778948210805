import React from "react";
import { Link } from "react-router-dom";
const defaultCover = require("../../../assets/img/covers/sample-cover.jpg");

const Episode = ({
  name = "Episode Name",
  image = defaultCover,
  durationText = "2 min",
}) => {
  return (
    <div className="">
      <div className="flex space-x-5">
        <figure className="w-[8rem] h-[8rem] bg-gray-100 flex flex-col rounded-sm shadow-md overflow-auto relative">
          <img src={image} alt={name} className="w-full h-full object-cover" />
        </figure>

        <div className="flex-1 pr-10">
          <div>
            <h1 className="font-semibold text-md">{name}</h1>
            <h1 className="font-normal text-md">{durationText}</h1>
          </div>

          <p className="mt-2 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>

        <div className="h-[8rem] flex flex-col justify-center">
          <button
            type="button"
            class="h-10 w-10 rounded-full bg-gray-50 shadow-sm flex flex-col justify-center items-center"
            aria-label="Écouter"
          >
            <svg
              class="svg-icon svg-icon-play"
              focusable="false"
              height="1em"
              role="img"
              width="1em"
              viewBox="0 0 12 12"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M2.5.5v11l9-5.5z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

Episode.propTypes = {};

export default Episode;
