import { connect } from "react-redux";
import { getPodcast } from "../../actions/creators/library";
import { getPodcastCategories as getCategories } from "../../actions/metadata";
import { getPodcastEpisodes } from "../../actions/creators/podcast";
import {
  getEpisodesByPodcast,
  getPodcastById,
} from "../../selectors/PodcastSelectors";
import component from "../../content/creators/coursesingle";

const mapStateToProps = (state, ownProps) => ({
  loading: state.metadata.loadingCreate,
  createPodcastId: state.metadata.createPodcastId,
  podcast: getPodcastById(state, ownProps.match.params.slug),
  episodes: getEpisodesByPodcast(state, ownProps.match.params.slug),
});

const mapDispatchToProps = { getCategories, getPodcast, getPodcastEpisodes };

export default connect(mapStateToProps, mapDispatchToProps)(component);
