import axios from "axios";
import { API_BASE_HOST } from "../constants/api";
import { removeCookie } from "../helpers/cookies";

const ApiContext = axios.create({
  baseURL: `${API_BASE_HOST}/v1`,
  withCredentials: false,
  timeout: 30000,
  headers: { "Content-Type": "text/plain" },
});

ApiContext.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/sign-in?logout=auto");
      removeCookie("access_token");
      removeCookie("account_id");
    }
    return Promise.reject(error.response);
  }
);

/**
 * categories
 */

export function categoriesList(params = {}) {
  return ApiContext.get(`metadata/categories`, { params });
}

/**
 * prices
 */

export function pricesList(params = {}) {
  return ApiContext.get(`metadata/prices`, { params });
}
