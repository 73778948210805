import React from "react";
import Button from "../form/button";

const iconDefault = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="w-24 h-24"
    viewBox="0 0 512 512"
  >
    <path
      d="M376 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="32"
    />
    <path
      d="M288 304c-87 0-175.3 48-191.64 138.6-2 10.92 4.21 21.4 15.65 21.4H464c11.44 0 17.62-10.48 15.65-21.4C463.3 352 375 304 288 304z"
      fill="none"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-width="32"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="32"
      d="M88 176v112M144 232H32"
    />
  </svg>
);

const EmptyView = ({
  title,
  description,
  loading,
  onPrimaryAction,
  onSecondaryAction,
  icon = iconDefault,
}) => (
  <div className="flex flex-col justify-center items-center">
    <div className="flex justify-center">{icon}</div>

    <div className="my-10">
      <h1 className="text-center text-4xl">{title}</h1>
      <h1 className="text-center text-xl text-gray-500">{description}</h1>
    </div>

    <div className="flex space-x-3">
      <div>
        <Button
          disabled={loading}
          iconLeft={null}
          iconRight={null}
          content="Annuler"
          onClick={() => {
            if (onSecondaryAction) onSecondaryAction();
          }}
        />
      </div>
      <div>
        <Button
          iconLeft={null}
          iconRight={null}
          disabled={loading}
          content="Créer nouveau"
          buttonStyle={"primary"}
          onClick={() => {
            if (onPrimaryAction) onPrimaryAction();
          }}
        />
      </div>
    </div>
  </div>
);

export default EmptyView;
