import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { generateUUID } from "../../utils/StringUtil";

const listId = `list__${Math.random()}_${generateUUID()}`;
const listRef = React.createRef();

const DataGrid = ({
  listTitle = "List title",
  items = [],
  renderItem = null,
}) => {
  const [index, setIndex] = useState(1);

  useEffect(() => {}, []);
  return (
    <div className="py-10">
      <div className="px-20 flex justify-between">
        <h1 className="text-4xl font-semibold">{listTitle}</h1>

        <div className="flex space-x-3">
          <button
            type="button"
            class="h-10 w-10 rounded-full bg-gray-50 shadow-sm flex flex-col justify-center items-center"
            aria-label="Précedent"
            onClick={() => {
              if (index > 0) {
                setIndex(index - 1);

                let element = listRef.current;
                if (element) {
                  // let translate = 350 * index;
                  //element.style.transitionDuration = "0.5s";
                  //element.style.transform = `translate(-${translate}px)`;
                }
              }
            }}
          >
            <ChevronLeftIcon className="w-6 h-6" />
          </button>

          <button
            type="button"
            class="h-10 w-10 rounded-full bg-gray-50 shadow-sm flex flex-col justify-center items-center"
            aria-label="Suivant"
            onClick={() => {
              if (index < items.length - 1) {
                setIndex(index + 1);

                let element = listRef.current;
                if (element) {
                  // let translate = 350 * index;
                  //element.style.transitionDuration = "0.5s";
                  //element.style.transform = `translate(-${translate}px)`;
                }
              }
            }}
          >
            <ChevronRightIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
      <div
        className="pt-10 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-3 px-20"
        id={listId}
        ref={listRef}
      >
        {items.map((item, x) => renderItem({ item, x }))}
      </div>
    </div>
  );
};

DataGrid.propTypes = {};

export default DataGrid;
