/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import HomeHeader from "../../components/header/home";
import SliderMain from "../../components/hero/apps";
import FooterPage from "../../components/footer";
import Button from "../../components/form/button";
import { ExternalLinkIcon } from "@heroicons/react/outline";

class HomePreachs extends Component {
  componentDidMount() {
    document.title = "Accueil";
  }

  render() {
    return (
      <div className="h-full bg-dark">
        <HomeHeader selectedIndex={3} />
        <section className="h-[70vh] relative bg-gradient-to-r from-purple-200 via-purple-400 to-purple-800">
          <img
            alt="cover"
            className="h-full w-full object-cover"
            src={require("../../../assets/img/misc/photo-music-section-8.jpeg")}
          />
          <div className="backdrop-blur-sm bg-dark-500/20 absolute inset-0" />

          <div className="absolute inset-0">
            <div className="container mx-auto h-full flex items-center">
              <div className="md:w-1/2">
                <h1 className="text-white text-6xl leading-[5rem] font-bold uppercase">
                  Prédications, Messages, Enseignements et Sermons bibliques
                </h1>

                <div className="flex space-x-5 mt-10">
                  <a
                    href="https://dashboard.yoka.cd"
                    target="_blank"
                    rel="noreferrer"
                    className="px-6 py-3 rounded-full text-primary-500 border border-primary-500 bg-white hover:bg-primary-500 hover:text-white flex items-center space-x-3"
                  >
                    <span className="font-semibold">
                      Télécharger notre application
                    </span>
                    <ExternalLinkIcon className="w-5 h-5" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto flex flex-col md:flex-row space-x-10 py-20">
            <div className="w-1/2 h-[30rem] overflow-hidden">
              <img
                alt="cover"
                className="h-full w-full object-cover"
                src={require("../../../assets/img/misc/photo-music-section-10.jpeg")}
              />
            </div>
            <div className="w-1/2 space-y-5 py-10">
              <h1 className="text-5xl font-bold uppercase">
                Suivez vos prédications
                <br />
                <span className="text-secondary-500">en ligne</span>
              </h1>
              <p className="text-xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <div className="flex">
                <a
                  href="https://dashboard.yoka.cd"
                  target="_blank"
                  rel="noreferrer"
                  className="px-6 py-3 rounded-full text-primary-500 border border-primary-500 bg-white hover:bg-primary-500 hover:text-white flex items-center space-x-3"
                >
                  <span className="font-semibold">
                    Découvrir notre application
                  </span>
                  <ExternalLinkIcon className="w-5 h-5" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto flex flex-col md:flex-row space-x-10 items-center py-20">
            <div className="w-1/2 space-y-5">
              <h1 className="text-5xl font-bold uppercase">
                Faites passer votre culte
                <br />
                <span className="text-secondary-500">en ligne</span>
              </h1>
              <p className="text-xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <div className="flex">
                <a
                  href="https://dashboard.yoka.cd"
                  target="_blank"
                  rel="noreferrer"
                  className="px-6 py-3 rounded-full text-primary-500 border border-primary-500 bg-white hover:bg-primary-500 hover:text-white flex items-center space-x-3"
                >
                  <span className="font-semibold">
                    Enregistrer vos prédications dès maintenant
                  </span>
                  <ExternalLinkIcon className="w-5 h-5" />
                </a>
              </div>
            </div>
            <div className="w-1/2 h-[30rem] overflow-hidden">
              <img
                alt="cover"
                className="h-full w-full object-cover"
                src={require("../../../assets/img/misc/photo-music-section-9.jpeg")}
              />
            </div>
          </div>
        </section>
        <SliderMain targetText={"vos cultes"} />
        <FooterPage />
      </div>
    );
  }
}

export default HomePreachs;
