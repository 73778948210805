/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Header from "../../components/header/home";
import Button from "../../components/form/button";
import Table from "../../components/table/table";
import PageLayout from "../account/layout";
import AnalyticData from "../../components/analytics/minimal";
import CreateEpisode from "../../containers/creators/lessonepisodecreate";
import EditEpisode from "../../containers/creators/podcastepisodeinfo";
import { PencilIcon, PlayIcon } from "@heroicons/react/outline";

class PagePodcast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "TAB_EPISODES",
      wizardVisible: false,
      wizardEditVisible: false,
      selectedEpisode: null,
    };
  }

  componentDidMount() {
    document.title = "Cours - Yoka.cd";
    setTimeout(() => this.fetchPodcast(), 500);
  }

  fetchPodcast() {
    const { getPodcast, getPodcastEpisodes, match } = this.props;
    if (getPodcast) getPodcast(match.params.slug);
    if (getPodcastEpisodes) getPodcastEpisodes(match.params.slug);
  }

  render() {
    const {
      loadingCreate,
      profileName,
      emailAddress,
      genderSex,
      addressCountry,
      birthDate,
      podcast,
      episodes,
      createEpisode,
      match,
    } = this.props;
    const { activeTab, wizardVisible, wizardEditVisible, selectedEpisode } =
      this.state;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10 relative">
            <div className="flex space-x-3">
              <div className="w-32 h-32 bg-gray-100">
                {podcast && (
                  <img
                    alt={podcast.title}
                    src={podcast.photo_url}
                    className="h-full w-full object-cover"
                  />
                )}
              </div>
              {podcast && (
                <h1 className="text-4xl font-bold">{podcast.title}</h1>
              )}
              {!podcast && <h1 className="text-4xl font-bold">Cours</h1>}
            </div>
            <hr className="my-5" />
            <div className="grid md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 my-10">
              {[
                {
                  title: podcast ? podcast.start_position : "0",
                  sub_title: "Classement",
                },
                {
                  title: podcast ? podcast.stream_count : "0",
                  sub_title: "Ecoutes",
                },
                {
                  title: podcast ? podcast.listener_count : "0",
                  sub_title: "Auditeurs",
                },
                {
                  title: podcast ? podcast.subscriber_count : "0",
                  sub_title: "Abonnés",
                },
              ].map((item, x) => (
                <AnalyticData
                  key={x}
                  title={item.title}
                  sub_title={item.sub_title}
                />
              ))}
            </div>
            <hr className="my-5" />
            <h1 className="text-xl my-10 font-bold">Episode</h1>
            {activeTab === "TAB_EPISODES" && (
              <>
                <Table
                  dataSource={episodes}
                  columns={[
                    {
                      field: "title",
                      title: `${episodes.length} Leçons`,
                      renderElement: (record) => (
                        <div class="flex items-center">
                          {record.is_published && (
                            <span class="bg-green-500 h-2 w-2 rounded-full mr-2"></span>
                          )}
                          {!record.is_published && (
                            <span class="bg-gray-500 h-2 w-2 rounded-full mr-2"></span>
                          )}
                          <span class="font-normal">{record.title}</span>
                        </div>
                      ),
                    },
                    { field: "stream_count", title: "Ecoutes", width: "10%" },
                    {
                      field: "listener_count",
                      title: "Auditeurs",
                      width: "10%",
                    },
                    {
                      field: "published_at",
                      title: "Date publication",
                      width: "5%",
                      srOnly: true,
                      renderElement: (record) => (
                        <div class="flex items-center space-x-5">
                          <a href="javascript:void(0);" onClick={() => {}}>
                            <PlayIcon className="w-4 h-4" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              this.setState({
                                selectedEpisode: record,
                                wizardEditVisible: true,
                              });
                            }}
                          >
                            <PencilIcon className="w-4 h-4" />
                          </a>
                        </div>
                      ),
                    },
                  ]}
                />

                <div className="flex py-10 space-x-4">
                  <div>
                    <Button
                      content="Ajouter une leçon"
                      iconLeft={null}
                      iconRight={null}
                      onClick={() => this.setState({ wizardVisible: true })}
                    />
                  </div>
                  <div>
                    <Button
                      content="Paramètres du cours"
                      iconLeft={null}
                      iconRight={null}
                      onClick={() => {
                        let url = `/my-account/learning/${match.params.slug}/edit`;
                        this.props.history.push(url);
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {activeTab === "TAB_DATA" && (
              <>
                <div className="divide-y">
                  <div className="flex justify-between py-3">
                    <h1 className="text-gray-500">Nom du profile</h1>
                    <h1>{profileName}</h1>
                  </div>
                  <div className="flex justify-between py-3">
                    <h1 className="text-gray-500">Adresse email</h1>
                    <h1>{emailAddress}</h1>
                  </div>
                  <div className="flex justify-between py-3">
                    <h1 className="text-gray-500">Sexe</h1>
                    <h1>{genderSex}</h1>
                  </div>
                  <div className="flex justify-between py-3">
                    <h1 className="text-gray-500">Date de naissance</h1>
                    <h1>{birthDate}</h1>
                  </div>
                  <div className="flex justify-between py-3">
                    <h1 className="text-gray-500">Pays</h1>
                    <h1>{addressCountry}</h1>
                  </div>
                </div>
              </>
            )}
            {wizardVisible && (
              <div className="absolute inset-0 bg-white">
                <CreateEpisode
                  podcastId={match.params.slug}
                  loading={loadingCreate}
                  createEpisode={createEpisode}
                  onRequestClose={() => this.setState({ wizardVisible: false })}
                />
              </div>
            )}
            {wizardEditVisible && (
              <>
                {selectedEpisode && (
                  <div className="absolute inset-0 bg-white">
                    <EditEpisode
                      podcastId={match.params.slug}
                      episode={selectedEpisode}
                      onRequestClose={() =>
                        this.setState({ wizardEditVisible: false })
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PagePodcast;
