import React, { Component } from "react";
import FooterPage from "../../components/footer";
import Header from "../../components/header/home";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";

class PageSignin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountUsername: "",
      accountPassword: "",
    };
  }

  componentDidMount() {
    document.title = "Connexion - Yoka.cd";
  }

  onRequestSubmit() {
    const { accountPassword, accountUsername } = this.state;

    if (accountPassword === "" || accountUsername === "") return;

    let bodyParams = {
      username: accountUsername,
      password: accountPassword,
      auth_provider: "email",
    };

    const { loginUser } = this.props;
    if (loginUser) loginUser(bodyParams);
  }

  render() {
    const { loading } = this.props;
    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <div className="bg-white py-10">
          <div className="h-full flex flex-col justify-center items-center">
            <div className="mx-auto md:w-[70%] xl:max-w-2xl 2xl:w-[35rem] border border-gray-100 bg-gray-50 p-20">
              <h1 className="text-4xl font-bold leading-tight my-3 text-center">
                Se connecter à Yoka.cd
              </h1>
              <div className="space-y-5 my-4">
                <p className="text-center">
                  Connectez-vous et retrouvez tout votre contenu !
                </p>
              </div>

              <div className="space-y-3">
                <Button
                  disabled={loading}
                  content="Se connecter avec Facebook"
                  iconRight={null}
                  iconLeft={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  }
                />
                <Button
                  disabled={loading}
                  content="Se connecter avec Google"
                  iconRight={null}
                  iconLeft={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 512 512"
                    >
                      <path d="M473.16 221.48l-2.26-9.59H262.46v88.22H387c-12.93 61.4-72.93 93.72-121.94 93.72-35.66 0-73.25-15-98.13-39.11a140.08 140.08 0 01-41.8-98.88c0-37.16 16.7-74.33 41-98.78s61-38.13 97.49-38.13c41.79 0 71.74 22.19 82.94 32.31l62.69-62.36C390.86 72.72 340.34 32 261.6 32c-60.75 0-119 23.27-161.58 65.71C58 139.5 36.25 199.93 36.25 256s20.58 113.48 61.3 155.6c43.51 44.92 105.13 68.4 168.58 68.4 57.73 0 112.45-22.62 151.45-63.66 38.34-40.4 58.17-96.3 58.17-154.9 0-24.67-2.48-39.32-2.59-39.96z" />
                    </svg>
                  }
                />
              </div>
              <hr className="my-10" />
              <div className="space-y-3">
                <TextField
                  type="email"
                  label={"Adresse email"}
                  placeholder="ex : contact@example.com"
                  onChange={(accountUsername) =>
                    this.setState({ accountUsername })
                  }
                />
                <TextField
                  type="password"
                  label={"Mot de passe"}
                  placeholder="Mot de passe"
                  onChange={(accountPassword) =>
                    this.setState({ accountPassword })
                  }
                />
              </div>
              <div className="space-y-3 mt-10 flex flex-col">
                <Button
                  disabled={loading}
                  content="Connexion"
                  iconRight={null}
                  iconLeft={null}
                  onClick={() => this.onRequestSubmit()}
                />
                <div className="flex space-x-2 text-center justify-center">
                  <a
                    className="md:inline-flex flex items-center justify-center mt-5 px-10 py-2 rounded-full text-primary-500 bg-white hover:bg-primary-500 hover:text-white"
                    href={"/password/forgot"}
                  >
                    <span className="text-sm font-bold">
                      Mot de passe oublié ?
                    </span>
                  </a>
                  <a
                    className="md:inline-flex flex items-center justify-center mt-5 px-10 py-2 rounded-full text-primary-500 bg-white hover:bg-primary-500 hover:text-white"
                    href={"/sign-up"}
                  >
                    <span className="text-sm font-bold">
                      Vous n'avez pas de compte ?
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterPage />
      </div>
    );
  }
}

export default PageSignin;
