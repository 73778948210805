import React, { Component } from "react";
import Header from "../../components/header/home";
import PageLayout from "./layout";
import Button from "../../components/form/button";
import { getCookie } from "../../helpers/cookies";

class PageAccount extends Component {
  componentDidMount() {
    document.title = "Compte - Yoka.cd";
    setTimeout(() => this.fetchProfile(), 500);
  }

  fetchProfile() {
    let profileId = getCookie("profile_id");

    const { getProfile } = this.props;
    if (getProfile) getProfile(profileId);
  }

  render() {
    const {
      loading,
      profileName,
      emailAddress,
      genderSex,
      addressCountry,
      birthDate,
    } = this.props;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Vue d'ensemble du compte</h1>
            <hr className="my-5" />
            <h1 className="text-xl my-10 font-bold">Profil</h1>
            <div className="divide-y">
              <div className="flex justify-between py-3">
                <h1 className="text-gray-500">Nom du profile</h1>
                <h1>{profileName}</h1>
              </div>
              <div className="flex justify-between py-3">
                <h1 className="text-gray-500">Adresse email</h1>
                <h1>{emailAddress}</h1>
              </div>
              <div className="flex justify-between py-3">
                <h1 className="text-gray-500">Sexe</h1>
                <h1>{genderSex}</h1>
              </div>
              <div className="flex justify-between py-3">
                <h1 className="text-gray-500">Date de naissance</h1>
                <h1>{birthDate}</h1>
              </div>
              <div className="flex justify-between py-3">
                <h1 className="text-gray-500">Pays</h1>
                <h1>{addressCountry}</h1>
              </div>
            </div>

            <div className="flex py-10">
              <div>
                <Button
                  content="Modifier le profil"
                  iconLeft={null}
                  iconRight={null}
                  onClick={() => {
                    this.props.history.push("/my-account/profile");
                  }}
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageAccount;
