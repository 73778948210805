import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { generateUUID } from "../../utils/StringUtil";

const listId = `list__${Math.random()}_${generateUUID()}`;

const DataList = ({
  listTitle = "List title",
  items = [],
  renderItem = null,
  slidesToShow = 5,
  slidesToScroll = 4,
}) => {
  const listRef = useRef();

  useEffect(() => {}, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll,
  };
  return (
    <div className="py-10">
      <div className="px-20 flex justify-between">
        <h1 className="text-4xl font-semibold">{listTitle}</h1>

        <div className="flex space-x-3">
          <button
            type="button"
            class="h-10 w-10 rounded-full bg-gray-50 shadow-sm flex flex-col justify-center items-center"
            aria-label="Précedent"
            onClick={() => {
              listRef.current.slickPrev();
            }}
          >
            <ChevronLeftIcon className="w-6 h-6" />
          </button>

          <button
            type="button"
            class="h-10 w-10 rounded-full bg-gray-50 shadow-sm flex flex-col justify-center items-center"
            aria-label="Suivant"
            onClick={() => {
              listRef.current.slickNext();
            }}
          >
            <ChevronRightIcon className="w-6 h-6" />
          </button>
        </div>
      </div>

      {items.length > 4 && (
        <div className="pt-10 pl-20" id={listId}>
          <Slider
            ref={listRef}
            {...sliderSettings}
            style={{ paddingLeft: 20, paddingTop: 10 }}
          >
            {items.map((item, x) => renderItem({ item, x }))}
          </Slider>
        </div>
      )}

      {items.length <= 4 && (
        <div
          className="pt-10 flex space-x-5 pl-20 pr-20 overflow-hidden"
          id={listId}
        >
          {items.map((item, x) => renderItem({ item, x }))}
        </div>
      )}
    </div>
  );
};

DataList.propTypes = {};

export default DataList;
