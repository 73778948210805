import React, { Component } from "react";
const IMAGE_COVER = require("../../../assets/img/shapes/shape-phone.jpg");

const BADGE_APPSTORE = require("../../../assets/img/logos/app-store.png");
const BADGE_GOOGLEPLAY = require("../../../assets/img/logos/google-play-badge.png");

class HeroApps extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { targetText = "des podcasts" } = this.props;
    return (
      <section className={"flex flex-col justify-center bg-primary-700 py-20"}>
        <div className="container mx-auto flex justify-between">
          <div className="md:w-1/2">
            <h1 className="text-white text-6xl leading-[4.5rem] font-semibold">
              Avec Yoka, vous pouvez rechercher et télécharger des millions de
              contenu audio pour écouter hors-ligne depuis{" "}
              <span className="text-secondary-400">votre mobile</span>
            </h1>

            <div className="flex space-x-5 mt-10">
              <a href="/" title="Télécharger dans l'Apple Store">
                <img
                  src={BADGE_APPSTORE}
                  alt="hero cover"
                  className="h-[4rem]"
                />
              </a>
              <a href="/" title="Télécharger dans Google Play">
                <img
                  src={BADGE_GOOGLEPLAY}
                  alt="hero cover"
                  className="h-[4rem]"
                />
              </a>
            </div>
          </div>
          <div className=""></div>
        </div>
      </section>
    );
  }
}

export default HeroApps;
