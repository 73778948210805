import { connect } from "react-redux";
import { editEpisode } from "../../actions/creators/podcast";
import component from "../../content/creators/preachepisodeinfo";

const mapStateToProps = (state, ownProps) => ({
  loadingEdit: state.entities.loadingEdit,
});

const mapDispatchToProps = { editEpisode };

export default connect(mapStateToProps, mapDispatchToProps)(component);
