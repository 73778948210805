import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import HomeIndex from "../content/home/index";
import Error404 from "../content/common/page404";

import PageSignin from '../containers/session/signin'
import PageSignup from '../containers/session/signup'

import PodcastCategory from '../containers/podcast/category'
import PodcastProfile from '../containers/podcast/profile'
import PodcastSingle from '../containers/podcast/single'

import AccountPage from '../containers/account/index'
import AccountProfile from '../containers/account/profile'
import AccountPassword from '../containers/account/password'
import AccountLibrary from '../containers/account/library'
import AccountProfileCreate from '../containers/profile/create'
import AccountPodcastPage from '../containers/profile/podcast'
import AccountPodcastCreate from '../containers/profile/podcastcreate'
import AccountPodcastEdit from '../containers/profile/podcastedit'

import AccountLibraryPreach from '../containers/creators/preachs'
import AccountPreachPage from '../containers/profile/preach'
import AccountPreachCreate from '../containers/profile/preachcreate'
import AccountPreachEdit from '../containers/profile/preachedit'

import AccountLibraryLearning from '../containers/creators/learning'
import AccountLearningPage from '../containers/creators/course'
import AccountLearningCreate from '../containers/creators/coursecreate'
import AccountLearningEdit from '../containers/creators/courseedit'

import AccountLibraryMusic from '../containers/creators/music'
import AccountMusicPage from '../containers/creators/release'
import AccountMusicCreate from '../containers/creators/releasecreate'
import AccountMusicEdit from '../containers/creators/courseedit'
import HomeEducation from "../content/home/courses";
import HomePreachs from "../content/home/preachs";
import HomePodcasts from "../content/home/podcast";

class BaseRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomeIndex} />
        <Route exact path="/home" component={HomeIndex} />
        <Route exact path="/online-courses" component={HomeEducation} />
        <Route exact path="/preachs" component={HomePreachs} />
        <Route exact path="/podcasts" component={HomePodcasts} />

        <Route exact path="/sign-in" component={PageSignin} />
        <Route exact path="/sign-up" component={PageSignup} />

        <Route exact path="/my-account" component={AccountPage} />
        <Route exact path="/my-account/profile" component={AccountProfile} />
        <Route exact path="/my-account/password" component={AccountPassword} />
        <Route exact path="/my-account/library" component={AccountLibrary} />
        <Route exact path="/my-account/profiles" component={AccountProfileCreate} />
        <Route exact path="/my-account/profiles/create" component={AccountProfileCreate} />

        <Route exact path="/my-account/podcasts/create" component={AccountPodcastCreate} />
        <Route exact path="/my-account/podcasts/:slug/edit" component={AccountPodcastEdit} />
        <Route exact path="/my-account/podcasts/:slug" component={AccountPodcastPage} />
        <Route exact path="/my-account/podcasts" component={AccountLibrary} />

        <Route exact path="/my-account/preachs/create" component={AccountPreachCreate} />
        <Route exact path="/my-account/preachs/:slug/edit" component={AccountPreachEdit} />
        <Route exact path="/my-account/preachs/:slug" component={AccountPreachPage} />
        <Route exact path="/my-account/preachs" component={AccountLibraryPreach} />

        <Route exact path="/my-account/learning/create" component={AccountLearningCreate} />
        <Route exact path="/my-account/learning/:slug/edit" component={AccountLearningEdit} />
        <Route exact path="/my-account/learning/:slug" component={AccountLearningPage} />
        <Route exact path="/my-account/learning" component={AccountLibraryLearning} />

        <Route exact path="/my-account/music/create" component={AccountMusicCreate} />
        <Route exact path="/my-account/music/:slug/edit" component={AccountMusicEdit} />
        <Route exact path="/my-account/music/:slug" component={AccountMusicPage} />
        <Route exact path="/my-account/music" component={AccountLibraryMusic} />

        <Route exact path="/my-account/:slug" component={AccountPage} />

        <Route exact path="/podcasts/profile/:slug" component={PodcastProfile} />
        <Route exact path="/podcasts/category/:slug" component={PodcastCategory} />
        <Route exact path="/podcasts/podcast/:slug" component={PodcastSingle} />
        <Route exact path="/podcasts/podcast/:slug/episode/:id" component={PodcastSingle} />


        <Route component={Error404} />
      </Switch>
    );
  }
}

export default BaseRouter;
